import CardholderSearch from "../components/CardholderSearch"
import { useAppSelector, selectFeatures } from "../store"
import { ErrorMessage } from "@ggl/components"

const ERROR_TITLE = "You account is not configured correctly"
const ERROR_MESSAGE = "Contact your administrator to have the correct privileges added to your account."

export default function Index() {
  const features = useAppSelector(selectFeatures)
  return features ? <CardholderSearch /> : <ErrorMessage title={ERROR_TITLE} message={ERROR_MESSAGE} />
}
